<template>
    <div>
        <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane disabled></el-tab-pane>
                <el-tab-pane :label="$t('friends.promotioninfo')" name="first" style="background-color: #1B1F24">
                    <div class="cardall">
                        <a-row style="width: 100%">
                            <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="8">
                                <div class="img1">
                                    <div style="padding: 10px 0 0 20px;height: 20%">
                                        <span class="currentlevel">{{$t('friends.currentlevel')}}</span>
                                        <el-tooltip class="item" effect="dark" :content="$t('friends.content')" placement="top-start">
                                            <el-button type="text" style="margin-left: 10px;color: black" icon="el-icon-question"></el-button>
                                        </el-tooltip>
                                    </div>
                                    <div style="display: flex;justify-content: space-around;height: 60%">
                                        <div style="color: white;width: 50%" class="justify-center">
                                            <div style="margin-top: 30px;margin-bottom: 5%;display: flex;justify-content: space-between">
                                                <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/friends/V.png">
                                                <!--<span style="font-size: 20px;font-family: 黑体" v-if="userprofile.introlevel">{{ userprofile.introlevel.level.substr(0, 2) }}</span>-->
                                                <span class="levelnum" v-if="userprofile.introlevel">{{userprofile.introlevel.level.substring(2) }}</span>
                                            </div>
                                        </div>
                                        <div style="width: 50%;" class="justify-center">
                                            <img src="https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/friends/medal.png" style="width: 100px;height: 100px;">
                                        </div>
                                    </div>
                                    <div class="gradedesc">
                                        <span class="gradetext">{{$t('friends.rolemembers')}}</span>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="8">
                                <div class="img2" style="display: flex;justify-content: space-around">
<!--                                    <div style="padding: 10px 0 0 20px;color: white;">-->
<!--                                        <span :class="{ 'highlight': tglink, 'obscurity': !tglink }" @click="switchdata(1)">{{$t('friends.promotionallinks')}}</span>-->
<!--                                        <span style="padding: 5px;">/</span>-->
<!--                                        <span :class="{ 'highlight': !tglink, 'obscurity': tglink }" @click="switchdata(2)">{{$t('friends.discountcodes')}}</span>-->
<!--                                    </div>-->
                                    <div style="display: flex;justify-content: space-around;color: white;">
                                        <div>
                                            <div style="margin-top: 10px;">
                                                <div style="color: white;padding: 5px 0">
                                                    <span class="tiptxt">{{$t('friends.promotionallinks')}}</span>
                                                </div>
                                                <div><span class="link">{{ userprofile.referrallink }}</span></div>
                                                <div style="margin-top: 10px;">
                                                    <el-button class="copybtn" type="primary" size="small" @click="copyLink(userprofile.referrallink)">{{$t('friends.copy')}}</el-button>
                                                </div>
                                                <div style="margin-top: 10px;">
                                                    <span class="ratiotxt">{{$t('friends.rebateratio')}} </span>
                                                    <span class="ratio">{{Math.round(userprofile.rebateratio*100)  }}%</span>
                                                </div>
                                            </div>
                                        </div>
<!--                                        <div style="width: 30%;" class="justify-center" v-if="userprofile.referrallink">-->
<!--                                            <vue-qr :text="userprofile.referrallink" style="width: 80px;height: 80px;"></vue-qr>-->
<!--                                        </div>-->
                                    </div>
                                    <div style="display: flex;justify-content: space-around;color: white;">
                                        <div>
                                            <div style="margin-top: 10px;">
                                                <div style="color: white;padding: 5px 0">
                                                    <span class="tiptxt">{{$t('friends.discountcodes')}}</span>
                                                </div>
                                                <div><span class="link">{{ userprofile.discountcode }}</span></div>
                                                <div style="margin-top: 10px;">
                                                    <el-button class="copybtn" type="primary" size="small" @click="copyLink(userprofile.discountcode)">{{$t('friends.copy')}}</el-button>
                                                </div>
                                                <div style="margin-top: 10px;" v-if="userprofile.discode&&userprofile.discode.type == '1'">
                                                    <span class="ratiotxt">{{$t('friends.discountratio')}}</span>
                                                    <span class="ratio" v-if="userprofile.discode">{{ userprofile.discode.discountratio * 100 }}%</span>
                                                </div>
                                                <div style="margin-top: 10px;" v-else>
                                                    <span class="ratiotxt">{{$t('friends.lowestdiscountratio')}}</span>
                                                    <span class="ratio" v-if="userprofile.discode">{{userprofile.discode.discountratio * 100 }}%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width: 30%;" class="justify-center"></div>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="8">
                                <div class="img3">
                                    <div style="padding: 10px 0 0 20px;">
                                        <span class="currentlevel">{{$t('friends.walletsandpoints')}}</span>
                                    </div>
                                    <div style="display: flex;justify-content: space-around;color: #1B1F24;padding: 10px">
                                        <div style="width: 50%;" class="justify-center">
                                            <div style="width: 80%">
                                                <div>
                                                    <span style="font-size: 18px;">{{$t('friends.wallet')}}</span>
                                                    <span style="font-size: 25px;font-weight: bolder;padding: 10px;">{{ userprofile.wallet }}$</span>
                                                </div>
                                                <div style="line-height: 12px;">
                                                    <span style="word-break:break-all;font-size: 12px;">{{$t('friends.applywithdrawal')}}</span>
                                                </div>
                                                <div class="justify-between" style="padding: 10px 0;">
                                                    <el-button class="withdrawbtn" type="primary" size="small" @click="toWithdrawal">{{$t('friends.withdraw')}}</el-button>
                                                    <el-button class="withdrawbtn" type="primary" size="small" @click="toLog">{{$t('friends.record')}}</el-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dashed"></div>
                                        <div style="width: 50%;" class="justify-center">
                                            <div style="width: 80%">
                                                <div style="margin-left: 10px;">
                                                    <span style="font-size: 18px;">{{$t('friends.freezeamount')}}</span>
                                                    <span style="font-size: 25px;font-weight: bolder;padding: 10px;">{{orderdata.freeze }}$</span>
                                                </div>
                                                <div style="margin-left: 10px;">
                                                    <span style="word-break:normal;word-wrap:normal;white-space:pre-wrap;font-size: 12px;">{{$t('friends.pointsused')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                    <div style="background-color: white;margin-top: 20px;border-radius: 7px;">
                        <div style="padding: 10px 0 0 40px;font-size: 20px;font-weight: bolder">
                            {{$t('friends.orderdetails')}}
                        </div>
                        <el-data-table ref="table1" v-bind="tableConfig1" :columns="columns1" style="margin-left: 30px;margin-top: 10px;width: 95%;margin-bottom: 40px;">
                        </el-data-table>
                        <div style="margin-top: -40px;padding:0 0 20px 40px;font-size: 20px;font-weight: bolder;color: black">
<!--                            <span>-->
<!--                                {{$t('friends.orderamount')}}：USD-->
<!--                                <span style="color: #13CFDA">{{orderdata.orderusd}}</span>-->
<!--                                EUR-->
<!--                                <span style="color: #13CFDA">{{orderdata.ordereur}}</span>-->
<!--                            </span>-->

                            <span style="margin-left: 20px;">
                               {{$t('friends.earnings')}}：USD
                                <span style="color: #13CFDA">{{orderdata.earningsusd}}</span>
<!--                                EUR <span style="color: #13CFDA">{{orderdata.earningseur}}</span>-->
                            </span>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('friends.withdrawalsrecord')" name="second">
                    <el-data-table ref="table" v-bind="tableConfig" :columns="columns"
                        style="margin-left: 30px;margin-top: 10px;width: 95%;min-height: 50vh">
                    </el-data-table>
                </el-tab-pane>
            </el-tabs>

            <el-dialog customClass="customWidth" title="Withdrawal application" :visible.sync="dialogFormVisible"
                :close-on-press-escape="false" :close-on-click-modal="false" :before-close="closeDialog">
                <el-form :model="form" :rules="rules" ref="ruleUser">
                    <el-form-item :label="$t('friends.accounts')" prop='account' :label-width="formLabelWidth">
                    <!-- <el-form-item label='Accounts' prop='account' :label-width="formLabelWidth"> -->
                        <el-input placeholder='Please enter the receiving account' clearable autocomplete="off" v-model='form.account' size="small" />
                    </el-form-item>
<!--                    <el-form-item label='계정 유형' prop='type' :label-width="formLabelWidth">-->
                    <el-form-item :label="$t('friends.accounttype')" prop='type' :label-width="formLabelWidth">
                    <!-- <el-form-item label='Account type' prop='type' :label-width="formLabelWidth"> -->
                        <el-select v-model="form.type" placeholder="please choose">
                            <el-option v-for="item in accountTypeOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('friends.withdrawalamount')" :label-width="formLabelWidth">
<!--                    <el-form-item label='인출 금액.' prop='price' :label-width="formLabelWidth">-->
                    <!-- <el-form-item label='Withdrawal Amount' prop='price' :label-width="formLabelWidth"> -->
<!--                        <el-input-number placeholder='Please enter the withdrawal amount' clearable autocomplete="off"-->
<!--                            v-model='form.price' :precision="2" :step="0.1" :min="30" :max="userprofile.wallet"></el-input-number>-->
                        {{userprofile.wallet}}
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
<!--                    <el-button @click="closeDialog()">취소</el-button>-->
                    <el-button @click="closeDialog()">{{$t('friends.cancel')}}</el-button>
                    <!-- <el-button @click="closeDialog()">Cancel</el-button> -->
<!--                    <el-button type="primary" @click="submitUser">제출</el-button>-->
                    <el-button type="primary" @click="submitUser">{{$t('friends.submit')}}</el-button>
                    <!-- <el-button type="primary" @click="submitUser">Submit</el-button> -->
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { getUserByid, insertWithdrawal,getOrderAmountByUserId } from "@/request/requests";
import { MessageError, MessageSuccess, getUserInfo } from "@/util/util"
// import VueQr from 'vue-qr'
export default {
    // components: {
    //     VueQr,
    // },
    name: "friends",
    data() {
        let validatePrice = (rule, value, callback) => {
            if (value <= 0) {
                callback(new Error(this.$t('friends.greaterthan')));
                // callback(new Error('Amount must be greater than 0!'));
            } else {
                callback();
            }
        };
        const comstatusOptions = [
            {
                value: "1",
                // label: "正常"
                // label: "표준"
                label: this.$t('friends.normal'),
            },
            {
                value: "0",
                // label: "无效"
                // label: "무효"
                label: this.$t('friends.invalid'),
            },
            {
                value: "2",
                label: this.$t('friends.freeze'),
            },
            // {
            //     value: "3",
            //     label: this.$t('friends.extracted'),
            // },
        ]
        const accountTypeOptions = [
            {
                value: "1",
                label: "USDT-TRC20",
            },
            {
                value: "2",
                label: "paypal"
            },
        ]
        const typeOptions = [
            {
                value: "1",
                // label: "판촉 링크"
                // label: "Promotion link"
                label: this.$t('friends.promotionlink'),
            },
            {
                value: "2",
                // label: "할인 코드"
                // label: "Discount code"
                label: this.$t('friends.discountcode'),
            },
        ]
        const statusOptions = [
            {
                value: "1",
                // label: "승인 대기 중"
                // label: "Waiting for approval"
                label: this.$t('friends.waitingforapproval'),
            },
            {
                value: "2",
                // label: "지불 대기 중"
                // label: "Waiting for payment"
                label: this.$t('friends.waitingforpayment'),
            },
            {
                value: "3",
                // label: "통과하지 못함"
                // label: "not pass"
                label: this.$t('friends.notpass'),
            },
            {
                value: "4",
                // label: "지불됨"
                // label: "Paid"
                label: this.$t('friends.paid'),
            },
        ]
        const columns = [
            { prop: 'id', label: this.$t('friends.cashwithdrawalid') },
            // { prop: 'id', label: 'Cash withdrawal ID' },
            { prop: 'account', label: this.$t('friends.accountsreceivable') },
            // { prop: 'account', label: 'Accounts receivable' },
            { prop: 'type', label:  this.$t('friends.accounttype'), formatter: row => this.accountTypeState(row) },
            // { prop: 'type', label: 'account type', formatter: row => this.accountTypeState(row) },
            { prop: 'price', label: this.$t('friends.amount') },
            // { prop: 'price', label: 'Amount(USD)' },
            { prop: 'status', label: this.$t('friends.status'), formatter: row => this.statusState(row) },
            // { prop: 'status', label: 'status', formatter: row => this.statusState(row) },
            { prop: 'createTime', label: this.$t('friends.applicationtime') },
            // { prop: 'createTime', label: 'Application time' },
            { prop: 'remark', label: this.$t('friends.processingresults')},
            // { prop: 'remark', label: 'Processing results' },
        ]
        const columns1 = [
            { prop: 'orderid', label: this.$t('friends.orderid') },
            // { prop: 'orderid', label: 'order ID' },
            { prop: 'useremail', label: this.$t('friends.useramount') },
            // { prop: 'useremail', label: 'User Amount' },
            { prop: 'sku.goodsSpu.goodsname', label: 'SKU' },
            { prop: 'sku.skuname',label: this.$t('friends.duration') },
            // { prop: 'sku.skuname', label: 'duration' },
            { prop: 'type', label: this.$t('friends.promotiontype'), formatter: row => this.typeState(row) },
            // { prop: 'type', label: 'promotion type', formatter: row => this.typeState(row) },
            // {prop: 'currency',label: '币种'},
            // { prop: 'status', label: '佣金状态', formatter: row => this.comstatusState(row) },
            { prop: 'status', label: this.$t('friends.status'), formatter: row => this.comstatusState(row) },
            { prop: 'price', label: this.$t('friends.orderamount') },
            // { prop: 'price', label: 'Order Amount(USD)' },
            { prop: 'profit', label: this.$t('friends.eainings')  },
            // { prop: 'profit', label: 'Eainings(USD)' },
            { prop: 'activetime', label: this.$t('friends.activetime') },
            { prop: 'createTime', label: this.$t('friends.createtime') },
            // { prop: 'createTime', label: 'createTime' },
        ]
        const searchForm = [
            {
                type: 'input',
                id: 'useremail',
                label: 'Email',
                el: {placeholder: 'Please enter the user email', clearable: true},
            },
            {
                type: 'select',
                id: 'status',
                label: 'Status',
                el: {placeholder: 'Please select', clearable: true},
                options:comstatusOptions
            },
        ]
        return {
            form: this.initForm(),
            dialogFormVisible: false,
            formLabelWidth: "120px",
            typeOptions,
            statusOptions,
            comstatusOptions,
            accountTypeOptions,
            tglink: true,
            discode: false,
            switchcss: true,
            activeName: 'first',
            columns,
            columns1,
            tableConfig: {
                url: '',
                dataPath: 'data.list',
                totalPath: 'data.total',
                paginationSize: 10,
                extraButtons: [],
                headerButtons: [],
                hasDelete: false,
                hasEdit: false,
                hasOperation: false,
                hasNew: false,
                operationAttrs: {
                    width: '100px',
                    fixed: 'right',
                },
            },
            tableConfig1: {
                searchForm,
                url: '',
                dataPath: 'data.list',
                totalPath: 'data.total',
                paginationSize: 10,
                extraButtons: [],
                headerButtons: [],
                hasDelete: false,
                hasEdit: false,
                hasOperation: false,
                hasNew: false,
                operationAttrs: {
                    width: '100px',
                    fixed: 'right',
                },
            },
            userinfo: "",
            userprofile: {},
            rules: {
                account: [
                    // { required: true, message: '수금 계좌를 입력하십시오.', trigger: 'blur' },],
                    { required: true, message: this.$t('home.pleaseenter')+' '+this.$t('friends.receivingaccount'), trigger: 'blur' },],
                    // { required: true, message: 'Please enter the receiving account', trigger: 'blur' },],
                price: [
                    // { required: true, message: '출금 금액을 입력하십시오.', trigger: 'blur' },
                    { required: true, message: this.$t('home.pleaseenter')+' '+this.$t('friends.withdrawalamount'), trigger: 'blur' },
                    // { required: true, message: 'Please enter the withdrawal amount', trigger: 'blur' },
                    { validator: validatePrice, trigger: 'blur' },
                ],
                type: [
                    // { required: true, message: '계정 유형을 입력하십시오.', trigger: 'blur' },
                    { required: true, message: this.$t('home.pleaseenter')+' '+this.$t('friends.accounttype'), trigger: 'blur' },
                ],
                    // { required: true, message: 'Please enter account type', trigger: 'blur' },],
            },
            orderdata:{
                orderusd:"",
                ordereur:"",
                earningsusd:"",
                earningseur:"",
            }
        }
    },
    methods: {
        comstatusState(row) {
            let data
            this.comstatusOptions.forEach(r => {
                if (r.value == row.status) {
                    data = r.label
                }
            })
            return data
        },
        handleClick(tab) {
            if (tab.index == '1') {
                this.handleTab1()
            }
            if (tab.index == '2') {
                this.handleTab2()
            }
        },
        toWithdrawal() {
            if (this.userprofile.wallet < 30) {
                // MessageError("현금 인출할 수 있는 금액이 없다.")
                // MessageError("无可提现金额")
                MessageError(this.$t('friends.nowithdrawableamount'))
                return
            }
            this.dialogFormVisible = true
        },
        toLog() {
            this.activeName = "second"
            this.handleTab2()
        },
        closeDialog() {
            this.dialogFormVisible = false
            this.form = this.initForm();
            this.$refs.ruleUser.clearValidate();
        },
        async submitUser() {
            this.$refs.ruleUser.validate((valid) => {
                if (valid) {
                    this.operatorUser()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async operatorUser() {
            this.form.userid = this.userprofile.id
            this.form.price = this.userprofile.wallet
            let res = await insertWithdrawal(this.form)
            if (res.status == "200") {
                MessageSuccess(res.msg)
                this.getUserByidInfo()
                this.closeDialog()
            } else {
                MessageError(res.msg)
            }
        },
        initForm() {
            return {
                userid: "", // 用户id
                account: "", // 收款账户
                price: "", // 提现金额
                type: "", // 账户类型
                status: "", // 提现状态
                operid: "", // 操作人id
                opername: "", // 操作人
                remark: "", // 备注
            }
        },
        typeState(row) {
            let data
            this.typeOptions.forEach(r => {
                if (r.value == row.type) {
                    data = r.label
                }
            })
            return data
        },
        statusState(row) {
            let data
            this.statusOptions.forEach(r => {
                if (r.value == row.status) {
                    data = r.label
                }
            })
            return data
        },
        accountTypeState(row) {
            let data
            this.accountTypeOptions.forEach(r => {
                if (r.value == row.type) {
                    data = r.label
                }
            })
            return data
        },
        switchdata(val) {
            if (val == 1) {
                this.tglink = true
                this.discode = false
            } else {
                this.tglink = false
                this.discode = true
            }
        },
        copyLink(val) {
            this.$copyText(val).then(
                function (e) {
                    console.log("copy arguments e:", e);
                    MessageSuccess("Copied successfully!");
                },
            );
        },
        async getUserByidInfo() {
            let res = await getUserByid(this.userinfo.id)
            if (res.status == "200") {
                this.userprofile = res.data
                this.userprofile.referrallink = "https://www.nfxbus.com/?uc=" + res.data.referrallink
            } else {
                MessageError(res.msg)
            }
        },
        async getOrderAmountInfo() {
            let res = await getOrderAmountByUserId(this.userinfo.id)
            if (res.status == "200") {
                this.orderdata = res.data
            } else {
                MessageError(res.msg)
            }
        },
        handleTab1() {
            this.getUserByidInfo()
            this.tableConfig1.axiosConfig = {
                headers: {
                    'Authorization': 'X-KILOGOD-AUTHORIZATION ' + this.$cookies.get('token'),
                }
            }
            this.tableConfig1.url = "commission/getUserById?reuserid=" + this.userinfo.id
            this.getOrderAmountInfo()
        },
        handleTab2() {
            this.tableConfig.axiosConfig = {
                headers: {
                    'Authorization': 'X-KILOGOD-AUTHORIZATION ' + this.$cookies.get('token'),
                }
            }
            this.tableConfig.url = "withdrawal/getByUserId?userid=" + this.userinfo.id
            this.$refs.table.getList()
        }
    },
    created() {
        let userInfo = getUserInfo()
        if (userInfo) {
            this.userinfo = JSON.parse(userInfo)
            this.handleTab1()
        }
    }
}
</script>

<style scoped>
.tabs {
    width: 96%;
    background-color: #ffffff;
    margin: 30px;
    border-radius: 5px;
}

/deep/ .el-tabs__header {
    margin-bottom: 0px;
}

/deep/ .el-tabs__item {
    font-weight: bolder;
}

.cardall {
    /*display: flex;*/
    /*flex-wrap: nowrap;*/
    /*padding-top: 20px;*/
}

.img1 {
    width: 97%;
    margin-top: 20px;
    background: linear-gradient(-90deg, #BFD2EA, #93F5EC);
    border: 1px solid #3F4751;
    border-radius: 7px;
}

.currentlevel{
    font-size: 20px;
    color: #1B1F24;
    font-family: Microsoft YaHei;
    font-weight: bold;
}

.levelnum{
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-style: italic;
    color: #1B1F24;
    display: block;
    margin-top: 20px;
}

.img2 {
    margin-top: 20px;
    background: linear-gradient(-36deg, #717983, #24292F);
    border: 1px solid #3F4751;
    border-radius: 7px;
}

.img3 {
    width: 95%;
    margin-left: 5%;
    margin-top: 20px;
    background: linear-gradient(-36deg, #BFD2EA, #93F5EC);
    border: 1px solid #3F4751;
    border-radius: 7px;
    color: #1B1F24;
    font-family: Microsoft YaHei;
}

.gradedesc {
    height: 20%;
    position: relative;
    background:rgba(0,0,0,0.2);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    justify-content: center;
}

.gradetext {
    font-size: 12px;
    flex-wrap: wrap;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: black;
    margin: 1%;
}

.link {
    color: #656C68;
    background-color: #ffffff;
    display: block;
    width: 180px;
    height: 40px;
    padding: 2px;
    border-radius: 2px;
    word-break: normal;
    word-wrap: normal;
    white-space: pre-wrap;
    font-size: 14px;
}
.tiptxt{
    font-weight: bolder;
    font-size: 1.5em;
}

.copybtn{
    width: 80px;
    background: linear-gradient(-90deg, #BFD2EA, #93F5EC);
    border:0;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1B1F24;
}
.ratio{
    font-size: 2.2em;
    font-weight: bolder;
    margin-left: 5px;
}
.ratiotxt{
    font-size: 1.2em;
}

.withdrawbtn{
    background: linear-gradient(-90deg, #6A7685, #1B1F24);
    border: 0;
    color: #93F5EC;
}

.dashed {
    width: 2px;
    height: 120px;
    background-image: linear-gradient(to bottom, #6A7685 0%, #1B1F24 50%, transparent 30%);
    background-size: 8px 8px;
    background-repeat: repeat-y;
}

/deep/ .customWidth {
    width: 40%;
}


.highlight {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bolder;
    cursor: pointer
}

.obscurity {
    font-size: 16px;
    color: #D9E2DE;
    cursor: pointer
}

@media screen and (max-width: 768px){
    .tabs {
        width: 100%;
        background-color: #ffffff;
        margin: 0px;
        border-radius: 5px;
    }
    .img1 {
        width: 100%;
    }
    .img2 {
        width: 100%;
        margin-left: 0%;
    }
    .img3 {
        width: 100%;
        margin-left: 0%;
    }
    /deep/ .customWidth {
        width: 95%;
    }
    .link {
        width: 140px;
        font-size: 0.7em;
    }
    .tiptxt{
        font-size: 1em;
    }
    .ratio{
        font-size: 1.5em;
    }
    .ratiotxt{
        font-size: 0.8em;
    }
    .withdrawbtn{
        font-size: 0.7em;
    }
}

</style>
